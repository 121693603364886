$default-color: #d23c1c;

$header-color: rgba(255,255,255,0.30);
$header-link-color: #D9B673;
$main-color: #7D2C2B;
$button-frc-color: #CE994D;
$welcomeWidgate-backgroundColor:#F4F4F4;
$roomWidgate-button-color:#CE7171;
$aboutWidgate-title-color:#CE7171;
$aboutWidgate-text-color: #DDDDDD;
$newsWidgate-backgroundColor:#F4F4F4;
$HamburgerColor: #DAB674;
$pageBackgroundColor: #F4F4F4;

$navbar-color: $default-color;
//primary-brand in bootstrap
$panel-color: $default-color;
//success-brand in bootstrap
$button-color: #ff9800;
//body color of primary-panel
$panel-body: #eaeaea;
// light text default color
$light-text: #555;

$screen-xs:                  480px !default;
$screen-sm:                  768px !default;
$screen-md:                  992px !default;
$screen-lg:                  1200px !default;
